import React from "react";

const Testimonial = () => {
  return (
    <div id="testimonial">
      <h2>Testimonial</h2>

      <section>
        <TestimonialCard
          name={"Abderrahmane"}
          feedback={"Working with Nayan has been an absolute delight. His mastery of Flutter shines through in every project he undertake."}
        />

        <TestimonialCard
          name={"Michael J."}
          feedback={
            "I had the pleasure of collaborating with Nayan on a Flutter project. His problem-solving skills resulted in an app that exceeded our expectations"
          }
        />

        <TestimonialCard
          name={"Chandresh V."}
          feedback={"I've seen the expertise Nayan brings to the table as a Flutter developer. His ability to turn complex ideas into elegant, user-friendly interfaces is remarkable."}
        />
      </section>
    </div>
  );
};

const TestimonialCard = ({ name, feedback }) => (
  <article>
    <img
      src="https://cdn-icons-png.flaticon.com/512/149/149071.png"
      alt="User"
    />
    <h4>{name}</h4>
    <p>{feedback}</p>
  </article>
);

export default Testimonial;
